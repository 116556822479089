<template>
	<!-- 发货 -->
	<div class="com-deliver-goods">
		<a-button :type="btnType" :size="btnSize" @click="showDelivery">{{ btnText }}</a-button>
		<a-modal v-model:visible="show" title="订单发货" width="700px" @ok="rightNowDeliver" @cancel="show=false" okText="立即发货" cancelText="暂不配送">
			<div class="cdo-add">
				<div class="cdo-add-item">
					<div class="cdoai-cycle">发</div>
					<div class="cdoai-add" v-if="info && info.outlet_info">
						<div>{{info.outlet_info.name}}-{{info.outlet_info.mobile}}</div>
						<div>{{info.outlet_info.address}}</div>
					</div>
					<div class="cdoai-add" v-else>
						<div>{{deliveryAdd.name}}-{{deliveryAdd.phone}}</div>
						<div>{{deliveryAdd.address}}</div>
					</div>
					<div style="width: 10px;"></div>
				</div>
				<div class="cdo-add-item">
					<div class="cdoai-cycle" style="background: #F44336;">收</div>
					<div class="cdoai-add" v-if="info">
						<div v-if="info.client_info">{{info.client_info.name}}-{{info.client_info.phone}}</div>
						<div v-if="info.client_info">{{info.client_info.region}}{{info.client_info.address}}</div>
					</div>
					<!-- <i v-if="info &&info.client_info" class="ri-file-copy-2-fill ri-lg" 
						style="color: rgb(64, 158, 255);cursor: pointer;" 
						v-clipboard:copy="info.client_info.name+' '+info.client_info.phone+' '+ info.client_info.region+info.client_info.address"  
						v-clipboard:success="copyAddress">
					</i> -->
				</div>
			</div>
			<a-tabs v-model:activeKey="active">
			    <a-tab-pane key="1" tab="商家自送">
					<div>
						<span class="f12">配送员：</span>
						<a-space>
							<a-select v-model:value="form.outlet_id" @change="changeOutlet" style="width: 200px;">
								<a-select-option value="">请选择门店</a-select-option>
								<a-select-option v-for="(item,index) in outlet" :key="index" :value="item.id">{{item.name}}</a-select-option>
							</a-select>
							<a-select v-model:value="form.persion_id" @change="getDeliveryMan" style="width: 200px;">
								<a-select-option value="">请选择配送员</a-select-option>
								<a-select-option v-for="(item,index) in personMan" :key="index" :value="item.id">{{item.name+'('+item.mobile+')'}}</a-select-option>
							</a-select>
						</a-space>
					</div>
					<div class="f12" style="margin-left:50px;">
						<span>没有配送员？</span>
						<router-link to="/set/delivery_set">
							<a-button type="link">立即添加</a-button>
						</router-link>
					</div>
					<div class="cdo-deliveryman-info" v-if="info && deliveryman">
						<div>
							<div>姓名：{{deliveryman.name}}</div>
							<div>电话：{{deliveryman.mobile}}</div>
							<div>所属门店：{{info.outlet_info.name}}</div>
						</div>
						<div>
							<div>待配送：{{deliveryman.to_delivery_num}}</div>
							<div>配送中：{{deliveryman.in_delivery_num}}</div>
							<div>已完成：{{deliveryman.quantityorder}}</div>
						</div>
					</div>
				</a-tab-pane>
			   <a-tab-pane key="2" tab="同城跑腿">
					<a-form :label-col="{span:3}" :wrapper-col="{span:16}">
						<a-form-item label="选择门店：">
							<a-select v-model:value="dadaForm.outlet_id">
								<a-select-option value="">请选择门店</a-select-option>
								<a-select-option 
									v-for="(item,index) in outlet" 
									:key="index" 
									:value="item.id">{{item.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="取货时间：">
							<a-space>
								<a-date-picker v-model:value="dadaForm.date" format="YYYY-MM-DD"/>
								<a-time-picker v-model:value="dadaForm.time" format="HH:mm" />
							</a-space>
						</a-form-item>
						<template v-if="type == 3">
							<a-form-item label="配送重量">
								<a-input v-model:value="dadaForm.weight" addon-after="kg"></a-input>
							</a-form-item>
						</template>
						<a-form-item label="打赏小费：">
							<a-input v-model:value="dadaForm.tips" placeholder="打赏小费" addon-after="元"></a-input>
						</a-form-item>
					</a-form>
				</a-tab-pane>
			    <a-tab-pane key="3" tab="快递发货">
					<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
						<a-form-item label="快递平台：">
							<a-select v-model:value="expressForm.express_company" @change="getExpress" placeholder="请选择快递公司">
								<a-select-option value="">请选择快递公司</a-select-option>
								<a-select-option :value="index" v-for="(item,index) in expressData" :key="index">{{item.express_company}}</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="快递单号：">
							<a-input placeholder="快递单号" v-model:value="expressForm.express_no"/>
						</a-form-item>
						<template v-if="type == 4 ">
							<a-form-item label="配送时间" name="delivery_time">
								<a-date-picker show-time placeholder="配送时间" v-model:value="expressForm.delivery_time" />
							</a-form-item>
							<a-form-item>
								<template #label>
									<span>下次配送时间</span>
									<a-tooltip title="填写该信息后自动生成下一次配送记录">
										<i class="el-icon-info"></i>
									</a-tooltip>
								</template>
								<a-date-picker show-time placeholder="配送时间" v-model:value="expressForm.next_send_time" />
								<!-- <a-date-picker v-model:value="expressForm.next_send_time" format="YYYY-MM-DD"/> -->
								<!-- <a-date-picker
									v-model:value="expressForm.next_send_time" placeholder="预计送达时间">
								</a-date-picker> -->
							</a-form-item>
							<a-form-item label="下次配送重量">
								<a-input v-model:value="expressForm.next_send_weight" addon-after="kg"></a-input>
							</a-form-item>
						</template>
						<div class="f12" style="margin-left: 80px;">注意：可选择批量发货提升发货效率</div>
					</a-form>
				</a-tab-pane>
			</a-tabs>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import tool from '@/util/tool';
import goodsModel from '@/api/goods';
import outletModel from '@/api/addons/outlet';
import landModel from '@/api/land';
import adoptModel from '@/api/adopt';
import moment from 'moment'
export default{
	name:'com-deliver-goods',
	props:{
		id:{ 	//订单id
			type:Number,
			default:0
		},
		type:{	//订单类型 1集市订单 3认养  4土地 5积分商城订单
			type:Number,
			default:1
		},
		reback:{
			type:Boolean,
			default:false,
		},
		isShop:{
			type:Number,
			default:0
		},
		btnType:{			//按钮类型
			type:String
		},
		btnSize:{			//按钮尺寸
			type:String
		},
		btnText:{			//按钮文字
			type:String,
			default:"发货"
		}
	},
	setup(props,context){
		const state = reactive({
			show:false,
			active:'1',
			company:'dada',		//同城配送 dada（达达） , hummingBird（蜂鸟）
			expressData:[],		//快递公司
			form:{
				outlet_id:"",
				persion_id:'',
			},
			deliveryAdd:{	//发货地址
				name:'',
				phone:'',
				address:''
			},
			dadaForm:{
				tips:'',
				date:'',
				time:'',
				outlet_id:'',
				weight:'',	//认养专属
			},
			expressForm:{
				express_no:"",
				express_company:"",
				express_code:"",
				
				//土地
				delivery_time:"",
				next_send_time:'',
				next_send_weight:'',
			},
			info:null,		//配送信息
			deliveryman:null,
			personMan:[],	//配送员
			outlet:[],		//门店信息
		})

		state.expressData = tool.getExpressCompany()

		function showDelivery(){
			if( !props.id ) {
				tool.message("请选择要发货的订单","warning")
				return
			}
			state.show = true
			goodsModel.getProOrderDetail(props.id,props.type,res=>{
				state.info = res
				
				if( props.isShop == 0 ){ 	//自营
					// getOuletData()
					outletModel.getOutletList(1,99,null,res=>state.outlet = res.list)
					if( state.info.outlet_info ){
						state.form.outlet_id = state.info.outlet_info.id
						getPersionByOutlet(state.info.outlet_info.id)
					}
				}else{
					state.active = '3'
				}
			})
		}

		function changeOutlet(e){
			state.form.persion_id = ''
			state.outlet.forEach(item=>{
				if( item.id == e ){
					state.info.outlet_info = item
				}
			})
			state.deliveryman = null
			getPersionByOutlet(e)
		}

		function getPersionByOutlet(outlet_id){
			outletModel.getPersionByOutlet(outlet_id,res=>state.personMan =res)
		}

		function deliveyDada(){
			let form = JSON.parse(JSON.stringify(state.dadaForm))
			form.reback = props.reback
			form.id = props.id
			form.type = props.type
			if( props.type == 1 || props.type == 4 ) delete form.weight	//集市订单和土地订单没有重量

			goodsModel.deliveryByDada(form,()=>{
				state.show = false
				context.emit("success",true)
			})
		}

		function rightNowDeliver(){
			if( state.active == 1) selfSend()
			if( state.active == 2) deliveyDada()
			if( state.active == 3) expressSend()
		}

		function expressSend(){
			let { expressForm , info } = state, { id,type } = props

			if( type == 1 ){	//商城订单发货
				let data = {
					pk:id,
					type:4,
					order_type:1,
					name:info.client_info.name,
					mobile:info.client_info.phone,
					address:info.client_info.region + info.client_info.address,
					express_no:expressForm.express_no,
					express_company:expressForm.express_company,
					express_code:expressForm.express_code,
				}

				goodsModel.deliveryByExpress(data,()=>{
					state.expressForm.express_code = ''
					state.expressForm.express_no = ''
					state.expressForm.express_company = ''
					state.show = false
					context.emit('success',true)
				})
			}

			if( type == 4 ){	 //土地订单发货
				let data = {
					id:id,
					op:'send',
					name:info?.client_info?.name,
					phone:info?.client_info?.phone,
					address:info?.client_info?.address,
					express_no:expressForm.express_no,
					express_company:expressForm.express_company,
					delivery_time:moment(expressForm.delivery_time).format("YYYY-MM-DD HH:mm"),
					next_send_time:moment(expressForm.next_send_time).format("YYYY-MM-DD HH:mm"),
					next_send_weight:expressForm.next_send_weight,
				}

				landModel.setDeliveryStatus('send',data,"",()=>{
					state.expressForm.express_code = ''
					state.expressForm.express_no = ''
					state.expressForm.express_company = ''
					state.show = false
					context.emit('success',true)
				})
			}

			if( type == 3 ){	//认养订单发货
				let data = {
					pk:id,
					type:4,
					order_type:3,
					name:info.client_info.name,
					phone:info.client_info.phone,
					address:info.client_info.address,
					express_no:expressForm.express_no,
					express_company:expressForm.express_company,
					express_code:expressForm.express_code,
				}

				adoptModel.deliveryByExpress(data,()=>{
					state.expressForm.express_code = ''
					state.expressForm.express_no = ''
					state.expressForm.express_company = ''
					state.show = false
					context.emit('success',true)
				})
			}
		}
		
		function selfSend(){
			let data = {
				id:props.id,
				order_type:props.type,
				persion_id:state.form.persion_id,
				outlet_id:state.form.outlet_id
			}

			goodsModel.deliveryByMerchant(data,()=>{
				state.form.persion_id = ''
				state.show = false
				context.emit('success',true)
			})
		}

		function getExpress(e){
			state.expressForm.express_company = state.expressData[e].express_company
			state.expressForm.express_code = state.expressData[e].express_code
		}

		function getDeliveryMan(e){
			let persion = state.info.persion
			if( !e || e =='' ){
				state.deliveryman = null
			}
			for (var i = 0; i < persion.length; i++) {
				if( e == persion[i].id ){
					state.deliveryman = persion[i]
					break
				}
			}
		}

		function copyAddress(){
			tool.message("复制成功");
		}


		return{
			...toRefs(state),
			showDelivery,
			changeOutlet,
			getPersionByOutlet,
			deliveyDada,
			rightNowDeliver,
			selfSend,
			getExpress,
			getDeliveryMan,
			copyAddress
		}
	}
}
</script>

<style lang="scss" scoped>
	.cdo-add{
		border: 1px solid #eee;
		width: 100%;
		height: 140px;
		margin-bottom: 12px;
		&-item{
			width: 90%;
			padding: 0 5%;
			display: flex;
			// justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #eee;
			height: 70px;
			
			.cdoai-cycle{
				width: 35px;
				height: 35px;
				background: #000000;
				color: #fff;
				border-radius: 50%;
				text-align: center;
				line-height: 35px;
			}
			.cdoai-add{
				width: 85%;
				margin-left: 16px;
				>div:first-child{
					color: #000000;
					font-size: 14px;
					font-weight: bold;
					margin-bottom: 4px;
				}
				>div:last-child{
					color: #666;
					font-size: 12px;
				}
			}
			
			i{
				color: #999;
			}
		}
		&-item:last-child{
			border-bottom: none;
		}
	}
</style>
