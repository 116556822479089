import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"
import moment from 'moment'
import router from '@/router'
class Outlet{

    /**
     * 获取门店分类
     * @param {function} fn 
     */
    getOutlet(fn){
        $post('GetOutletClass').then(res=>{
            fn(res.data)
        })
    }


    /**
     * 新增或编辑分类信息
     * @param {object} param 
     * @param {function} fn 
     */
    addOrEditOutletClass(param,fn){
        if( !tool.returnMessage(param.name,"请输入分类名称")) return
        if( !param.id ) delete param.id

        $post("createOrUpdateClass",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)   
        })
    }

    /**
     * 删除门店信息
     * @param {number} id 
     * @param {function} fn 
     */
    deleteOutletClass(id,fn){
        tool.confirm("确认删除门店分类吗?").then(()=>{
            $post("deleteOutletClass",{id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取门店列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getOutletList(page,limit,extra,fn){
        let param = {page,limit}
        if(extra ){
            if( extra.name !='' ) param.name = extra.name
            if( extra.mobile !='' ) param.mobile = extra.mobile
        }
        common.getDataList("getOutletList",param).then(res=>fn(res))
    }

    /**
     * 获取门店详情
     * @param {number} id 
     * @param {Array} keys  需要的字段
     * @param {function} fn 
     */
    getOutletDetail(id,keys,fn){
        $post('getOutletDetail',{ id }).then(res=>{
            if( !keys ) {
                fn(res.data)
                return
            }
            let d = res.data , data = tool.filterForm(keys,res.data)
            data.images = d.images || []
            data.start_time = moment(d.start_time,"HH:mm")
            data.end_time = moment(d.end_time,"HH:mm")
            fn(data)
        },()=>{})
    }

    /**
     * 新增或编辑门店信息
     * @param {object} param 
     */
    addOrEditOutlet(param){
        if( !tool.returnMessage(param.name,'请填写门店名称')) return
        if( !tool.returnMessage(param.mobile,'请填写门店电话')) return
        if( !tool.returnMessage(param.contact_name,'请填写联系人姓名')) return
        if( !tool.returnMessage(param.description,'请填写门店简介')) return
        if( !param.id ) delete param.id
        
        if( typeof param.account_ids !="object") param.account_ids = [param.account_ids]

        $post("createOrUpdateOutlet",param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        }).catch(()=>{})
    }

    /**
     * 获取打印机列表
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getPrint(page,limit,fn){
        let param = { page,limit } 
        common.getDataList("getPrintList",param).then(res=>fn(res))
    }

    /**
     * 新增或编辑打印机信息
     * @param {object}} param 
     * @param {function} fn 
     */
    addOrEditPrint(param,fn){
        if( !param.id ) delete param.id
        $post("createOrEditPrint",param).then( res=> fn(res.data) )
    }

    /**
     * 删除打印机信息
     * @param {number} id 
     * @param {function} fn 
     */
    deletePrint(id,fn){
        tool.confirm("确认删除打印机信息吗?").then(()=>{
            $post('delPrints',{id}).then(res=>{
                tool.message('删除成功')
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取门店配送员
     * @param {number} outlet_id 
     * @param {function} callback 
     */
    getPersionByOutlet( outlet_id ,callback){
        $post('getPersionByOutlet',{outlet_id}).then(res=>callback(res.data))
    }


}
const outletModel = new Outlet()
export default outletModel